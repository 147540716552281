import { Injectable } from '@angular/core';
import { TranslationService } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class TranslationDomainService extends TranslationService {
  constructor() {
    super();
  }

  getActiveLanguage(): string {
    return 'en';
  }
}
