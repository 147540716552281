import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of, take } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { environment } from '@environment';
import { UserService } from '@ess-front/shared';
import { release } from '@release';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggerService {
  constructor(private userService: UserService) {
    this.initSentry();
  }

  logError(error: HttpErrorResponse | Error, message?: string): void {
    const errorToLog =
      error instanceof Error ? error : `Server returned code ${error.status} with message "${message}"`;
    if (Sentry.getClient()?.getOptions().enabled) {
      this.userService
        .getUser$()
        .pipe(
          catchError(() => of(undefined)),
          take(1),
        )
        .subscribe(user => {
          Sentry.captureException(errorToLog, { user });
        });
    }
    console.error(errorToLog);
  }

  private initSentry(): void {
    Sentry.init({
      denyUrls: ['localhost'],
      dsn: environment.sentryEngineDns,
      enabled: window.location.hostname !== 'localhost',
      environment: environment.production ? 'production' : 'development',
      ignoreErrors: ['400', '401', '403', '404'],
      release: release,
    });
  }
}
